<template>
    <button class="btn btn-sm btn-outline-primary" v-target:multiPublishedToOrgunits>{{ $t("Publish Selected Rows to Org.Unit") }}</button>
    <OModal name="publishedToOrgunits" ref="publishedToOrgunits" @hidden="() => { dsElements.refreshRow(); }">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('Published to Org.Units') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div class="modal-body">
                    <ODataGrid
                        :data-object="dsOrgUnits"
                        hideGridMenu
                        hideMultiselectColumn 
                        newRecordsPosition="bottom"
                        style="min-height:300px;"
                    >
                        <OColumn field="OrgUnit" editable width="470" v-slot:editor="{modelValue: row}" sortable>
                            <OOrgUnitsLookup textInput 
                                :bind="sel=>{ row.OrgUnit_ID = sel.ID; row.OrgUnit = sel.OrgUnit; }"
                                :value="row.OrgUnit ?? 'Select OrgUnit'" />
                        </OColumn>
                        <slot></slot>
                    </ODataGrid>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-outline-secondary" data-bs-dismiss="modal" >{{ $t("Close") }}</button>
                </div>
            </div>
        </div>
    </OModal>
    <OModal name="multiPublishedToOrgunits" ref="multiPublishedToOrgunits" @shown="onModalShow" @hidden="() => { dsElements.load(); }">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('Publish to Org.Unit') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div class="modal-body">
                    <p>{{ $t("Publish an Org.Unit to multiple rows") }}</p> 
                    <div class="mb-2 mt-2" style="font-weight:600;font-size:smaller" >
                        <span>{{ noOfSelected }}</span> <span></span>{{ $t(" rows selected") }}
                    </div>
                    <div style="max-width:300px">
                        {{ $t("Publish to Org.unit") }}:
                        <OOrgUnitsLookup 
                            textInput readonly
                            :bind="sel=>{ orgunit.ID = sel.ID; orgunit.Name = sel.OrgUnit}"
                            :value="orgunit.Name ?? 'Select OrgUnit'" 
                            class="form-select" >
                        </OOrgUnitsLookup>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary multipublish" :disabled="dsElements.selectionControl.selectedRows.length === 0" @click="publishToOrgUnits">{{ $t("Publish") }}</button>
                    <button class="btn btn-outline-secondary" data-bs-dismiss="modal" >{{ $t("Close") }}</button>
                </div>
            </div>
        </div>
    </OModal>
</template>
  
<script setup>
    import { ref, onMounted } from 'vue';
    import { getOrCreateProcedure } from 'o365-modules';
    import { alert } from 'o365-vue-services'
    import { OOrgUnitsLookup }  from 'o365-system-lookups';

    const props = defineProps({
        dsOrgUnits: {
            type: Object
        },
        dsElements: {
            type: Object
        },
        procPublishName: "String",
        elementNameText: {
            type: String,
            required: false
        }
    });

    const dsOrgUnits = props.dsOrgUnits;
    const dsElements = props.dsElements;
    const procPublish = getOrCreateProcedure({id: "procPublish", procedureName: props.procPublishName});
    const orgunit = ref({ Name: null, ID: null });
    const selectedIds = ref(null);
    const noOfSelected = ref(null);
    const multiPublishedToOrgunits = ref(null);
         
    async function onModalShow(){
        orgunit.value.Name = null;
        orgunit.value.ID = null;

        const ids = await dsElements.selectionControl.getSelectedRows();
        const preparedRows = [];
        ids.forEach(sel => {
            if (sel.ID !== null) preparedRows.push([sel.ID]);
        });
 
        selectedIds.value = preparedRows;
        noOfSelected.value = selectedIds.value.length;
    }

    function publishToOrgUnits(){
        var vElementsString = props.elementNameText ? props.elementNameText : $t("rows");
        procPublish.execute({
            Data: selectedIds.value,
            NewOrgUnit_ID: orgunit.value.ID
        }).then((i) => {
            if (i.Table[0].AlreadyPresent>0) {
                alert($t("Published to") + " " + i.Table[0].RowsAffected + " " + vElementsString + ". " + $t("Already existed in") + " " + i.Table[0].AlreadyPresent + " " + vElementsString, 'warning', { autohide : true , delay: 3000});
            }
            else {
                alert($t("Published to") + " " + i.Table[0].RowsAffected + " " + vElementsString, 'success', { autohide : true, delay: 3000 });
            }
            multiPublishedToOrgunits.value.hide();
        });
    }
</script>